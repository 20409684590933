<template>
  <div class="profile">

    <PageHeader heading="User Profile" />

    <b-row class="mt-2">
      <b-col cols="6">
        <img :src="$auth.user.picture">
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6">
        <h2>{{ $auth.user.name }}</h2>
        <p>{{ $auth.user.email }}</p>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6">
        <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'profile',
  components: {
    PageHeader,
  },
}
</script>
